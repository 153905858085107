<script setup lang="ts">
interface BaseDividerProps {
  navBar?: boolean
}

const props = withDefaults(defineProps<BaseDividerProps>(), {
  navBar: false,
})
</script>

<template>
  <hr
    :class="props.navBar ? 'hidden dark:border-nickel-700 lg:my-0.5 lg:block' : '-mx-6 my-6 dark:border-nickel-800'"
    class="border-t border-pumice-100"
  />
</template>
